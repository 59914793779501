<template>
  <v-app>
    <v-main class="grey lighten-3">
      <v-container class="pt-0">
        <v-row justify="center">
          <v-col cols="12" sm="7">
            <v-sheet>
              <TheHeader />
              <TheContent />
              <TheFooter />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TheContent from './components/TheContent';
import TheFooter from './components/TheFooter';
import TheHeader from './components/TheHeader';

export default {
  name: 'App',

  components: {
    TheHeader,
    TheContent,
    TheFooter,
  },
};
</script>
